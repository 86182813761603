import React from 'react';
import { Fade } from 'react-reveal';
import ReactVivus from 'react-vivus';

const SvgAnimation = ({ src, duration = 100, onReveal }) => {
    const [start, setStart] = React.useState();
    const revealed = () => {
        setStart(true);
        if (onReveal) {
            onReveal();
        }
    };

    return (
        <div>            
            { start ?
            <ReactVivus
                forceStart={false}
                id={src}
                option={{
                    file: src,
                    animTimingFunction: 'EASE',
                    duration: duration,
                    delay: 0,
                    type: 'sync',
                }}
                style={{ height: 'auto', width: '100%' }}
            /> : <img src={src} style={{ visibility: 'hidden' }} /> }
            <Fade onReveal={revealed}></Fade>
        </div>
    );
};

export default SvgAnimation;