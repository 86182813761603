import * as React from "react";
import { Zoom, Slide, Fade } from 'react-reveal';
import forwardIcon from '../images/content/forward-icon.svg';
import SvgAnimation from "./svg-animation";

const Feature = ({ title, children, icon, iconStyle, url }) => {
    const style = {
        marginTop: 0,
        ...iconStyle,
    };
    return (
        <div class="flex lg:mb-20">
            <div class="w-12 sm:w-24">
                <Fade>
                    <img src={icon} style={style} />
                </Fade>
            </div>
            <div class="flex-1 pb-5 pl-5">
                <h2 class="font-sans text-base lg:text-lg tracking-wide font-medium uppercase mb-5">
                    <Slide bottom duration={300}>{title}</Slide>
                </h2>
                <div class="text-gray font-sans text=base lg:text-lg font-light tracking-wide">
                    {children}
                </div>
                <a href={url} target="_blank"><img class="mt-8" src={forwardIcon} /></a>
            </div>
        </div>
    );
};

export default Feature;