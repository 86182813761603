import * as React from "react";
import Seo from "../components/seo"
import { Slide, Fade } from 'react-reveal';
import stats from '../images/companies/stats.svg';
import mentalHealthIcon from '../images/companies/mental-health-icon.svg';
import ahpIcon from '../images/companies/ahp-icon.svg';
import healthcareIcon from '../images/companies/healthcare-icon.svg';
import Forward from '../components/forward';
import Feature from '../components/feature';
import Sticker from '../components/sticker';
import SvgAnimation from "../components/svg-animation";
import StatsMobile from "../components/stats-mobile";
import cross from '../images/cross.svg';
import navitasIcon from '../images/companies/navitas.jpeg';
import sorensenIcon from '../images/companies/sorensen.png';
import velaIcon from '../images/companies/vela.jpg';

const CompaniesPage = () => {
  const [showStats, setShowStats] = React.useState();
  const revealStats = () => setShowStats(true);

  return (
    <div class="relative">
      <Seo title="Companies" />
      <div class="container mx-auto relative">
        <div class="grid grid-cols-12 mt-10 lg:mt-28 xl:mt-44">
          <div class="col-span-12 lg:col-span-5 flex flex-col">
            <h2 class="uppercase text-2xl lg:text-3xl font-sans font-medium tracking-wider lg:pl-10 mb-6 lg:mb-0">
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500}>Com</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={100}>pan</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={100}>ies</Slide>
              </div>
            </h2>
          </div>


          <div class="hidden lg:block col-span-7 font-sans text-3xl relative">
            <div style={{ width: 83 }} class="invisible lg:visible absolute -left-28 -top-12">
              <Fade delay={200} left distance="18px"><img src={cross} /></Fade>
            </div>
            <h1 class="uppercase font-sans text-lg lg:text-2lg xl:text-xl tracking-wider mb-32">
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500}>THOSE WHO CAME BEFORE</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={50}>HAVE BUILT BEST-IN-CLASS</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={100}>COMPANIES. CONSIDER</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={150}>THESE A BENCHMARK.</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={200}>NOT TO MATCH, </Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={250}>BUT TO SURPASS.</Slide>
              </div>
            </h1>
          </div>
        </div>

        <div class="lg:hidden">
          <h1 class="uppercase font-sans text-lg lg:text-xl tracking-wider">
            <Fade>
              THOSE WHO CAME BEFORE
              HAVE BUILT BEST-IN-CLASS
              COMPANIES. CONSIDER
              THESE A BENCHMARK.
              NOT TO MATCH,
              BUT TO SURPASS.
            </Fade>
          </h1>
        </div>

        {/*
        <div class="lg:hidden">
          <StatsMobile />
        </div>
        */}

        <div class="lg:block">
          <div class="z-10 relative">
            <SvgAnimation duration={100} src={stats} onReveal={revealStats} />
          </div>


          {/*
          <div style={{ opacity: (showStats ? 1 : 0), transition: 'all ease-in-out 1200ms' }} class="grid grid-cols-12 gap-10 -mt-44 mb-44 opacity-0">
            <div class="col-span-4">
              <Sticker label="Group Revenue" total="23" unit="£m" left={true} />
            </div>
            <div class="col-span-4">
              <Sticker label="Current year growth in revenues" total="65" unit="%" right={true} />
            </div>
            <div class="col-span-4">
              <Sticker label="Group Headcount" total="42" />
            </div>
          </div>
          */}
        </div>

        <div class="lg:px-10">
          <p class="uppercase font-sans text-black text-base lg:text-lg font-medium tracking-wide mb-0 mt-20">Human Capital</p>
          <div class="grid grid-cols-12 lg:gap-10 mt-20 pb-0">
            <div class="col-span-12 lg:col-span-6">
              <Feature
                title="HG AHP"
                icon={ahpIcon}
                url="https://hg-ahp.com"
              >
                <Fade>
                  <p>
                    Launch: 2017<br />
                    Revenue: £17m
                  </p>
                </Fade>
              </Feature>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Feature
                title="HG MH"
                icon={mentalHealthIcon}
                iconStyle={{ marginTop: -10, filter: 'grayscale(1)' }}
                url="https://hg-mh.com"
              >
                <Fade>
                  <p>
                    Launch: 2017<br />
                    Revenue: £13m
                  </p>
                </Fade>
              </Feature>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Feature
                title="NAVITAS"
                icon={navitasIcon}
                iconStyle={{ marginTop: -20, filter: 'grayscale(1)' }}
                url="https://navitas-nrg.com"
              >
                <Fade>
                  <p>
                    Launch: 2022<br />
                    Revenue: £7.5m
                  </p>
                </Fade>
              </Feature>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Feature
                title="Vela Medical"
                icon={velaIcon}
                iconStyle={{ marginTop: -20, filter: 'grayscale(1)' }}
                url="https://www.vela-mg.com/"
              >
                <Fade>
                  <p>
                    Launch: 2023<br />
                    Revenue: Start-up
                  </p>
                </Fade>
              </Feature>
            </div>
          </div>
        </div>

        <div class="lg:px-10">
          <p class="uppercase font-sans text-black text-base lg:text-lg font-medium tracking-wide mb-0 mt-20">Retail</p>
          <div class="grid grid-cols-12 lg:gap-10 mt-20 pb-0">
            <div class="col-span-12 lg:col-span-6">
              <Feature
                title="SØRENSEN"
                icon={sorensenIcon}
                url="http://sorensencrew.com/"
              >
                <Fade>
                  <p>
                    Launch: 2023<br />
                    Revenue: Start-up
                  </p>
                </Fade>
              </Feature>
            </div>
          </div>
        </div>

        <div class="lg:px-10">
          <p class="uppercase font-sans text-black text-base lg:text-lg font-medium tracking-wide mb-0 mt-20 lg:mt-0">Exited</p>
          <div class="grid grid-cols-12 lg:gap-10 mt-20 pb-20">
            <div class="col-span-12 lg:col-span-12">
              <Feature
                title="HUNTER HEALTHCARE"
                icon={healthcareIcon}
                url="http://hunter-healthcare.com/"
              >
                <Fade>
                  <p>
                    Launch: 2011<br />
                    Revenue: £44m <span class="text-sm">(in 2021 at point of exit)</span><br />                    
                    Exit: Private Equity backed MBO
                  </p>
                </Fade>
              </Feature>
            </div>            
          </div>
        </div>
        <Forward />
      </div>
    </div>
  )
};

export default CompaniesPage;
